@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap);
body.login-page{
  overflow:hidden !important;
}
.wrapper-login h1 {
  font-family: Arial, Arial, Helvetica, sans-serif;
  color: #fff;
  font-size: 50px;
  letter-spacing: -1px;
  text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.6);
  position: relative;
  z-index: 3;
text-align: center;
top:50px;
width:100%;
}
@media only screen and (max-width: 1420px) {
.wrapper-login h1 {
  top: 100px;
  width: 600px;
}
}
@media (max-width:1024px){
  .bottom-login{
    display:none !important;
  }
  .bird-container{
    display:none !important;
  }
}
@media only screen and (max-width: 820px) {

.wrapper-login .wind-turbine{
  display:block;
}
body.login-page{
  overflow-y:auto !important;
}
.wrapper-login h2{
  position:relative !important;
}
.wrap-input100{
  margin-bottom:10px !important;
}
.wrap-login100{
  padding-top:20px !important;
}
.wrapper-login h2{
  font-size: 20px !important;
}
.p-t-27{ padding-top:5px !important;}
.p-b-34{ padding-bottom: 5px !important;}
.logo{ position:static !important;}
.wrapper-login h1{
  font-size:25px;
  position:static;
  margin-top:10px;
}
.wrapper-login{
  min-height:100vh !important;
  overflow-y:auto !important;
}
.wrapper-login .container-login{
  min-height:1vh !important;
}
.container-login100{
  min-height:1vh !important;
}
}

.wrapper-login h2 {
font-family: Arial, Arial, Helvetica, sans-serif;
  color: #f4ba00;
  font-size: 42px;
  letter-spacing: -1px;
  position: absolute;
  z-index: 3;
bottom:0px;
left:10px;
display:flex;
padding:5pz 0;
}
.wrapper-login {
height: 100vh;
/*background-image:url('/bms_bg.png');*/
background-color:#0f1027;
background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
width: 100%;
  display: flex;
  flex-wrap: wrap;
  z-index: -1;
overflow-y: hidden;
min-height:1080px;
}
.wrapper-login .container-login {
  z-index: 111;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height:35rem;
width:100%
}
.wrapper-login .bottom-login{  
width: 1900px;
margin:0 auto;
overflow:hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper-login .bird {
  background-image: url('/bird-cells.svg');
  background-size: auto 100%;
  width: 88px;
  height: 125px;
  will-change: background-position;
  -webkit-animation-name: fly-cycle;
  animation-name: fly-cycle;
  -webkit-animation-timing-function: steps(10);
  animation-timing-function: steps(10);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.wrapper-login .bird--one {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.wrapper-login .bird--two {
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
  -webkit-animation-delay: -0.75s;
  animation-delay: -0.75s;
}
.wrapper-login .bird--three {
  -webkit-animation-duration: 1.25s;
  animation-duration: 1.25s;
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s;
}
.wrapper-login .bird--four {
  -webkit-animation-duration: 1.1s;
  animation-duration: 1.1s;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.wrapper-login .bird-container {
  position: absolute;
  top: 8%;
  left: -7.5vw;
  -webkit-transform: scale(0);
  transform: scale(0);
  will-change: transform;
  -webkit-animation-name: fly-right-one;
  animation-name: fly-right-one;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
width: 120px;
  height: 120px;
  border: 2px solid #f4ba00;
}
.wrapper-login .bird-container--one {
  -webkit-animation-duration: 15s;
  animation-duration: 15s;
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
.wrapper-login .bird-container--two {
  -webkit-animation-duration: 16s;
  animation-duration: 16s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.wrapper-login .bird-container--three {
  -webkit-animation-duration: 14.6s;
  animation-duration: 14.6s;
  -webkit-animation-delay: 9.5s;
  animation-delay: 9.5s;
}
.wrapper-login .bird-container--four {
  -webkit-animation-duration: 16s;
  animation-duration: 16s;
  -webkit-animation-delay: 10.25s;
  animation-delay: 10.25s;
}
@-webkit-keyframes fly-cycle {
100% {
background-position: -900px 0;
}
}
@keyframes fly-cycle {
100% {
background-position: -900px 0;
}
}
@-webkit-keyframes fly-right-one {
0% {
left: -10%;
-webkit-transform: scale(0.3);
transform: scale(0.3);
}
10% {
left: 10%;
-webkit-transform: translateY(2vh) scale(0.4);
transform: translateY(2vh) scale(0.4);
}
20% {
left: 30%;
-webkit-transform: translateY(0vh) scale(0.5);
transform: translateY(0vh) scale(0.5);
}
30% {
left: 50%;
-webkit-transform: translateY(4vh) scale(0.6);
transform: translateY(4vh) scale(0.6);
}
40% {
left: 70%;
-webkit-transform: translateY(2vh) scale(0.6);
transform: translateY(2vh) scale(0.6);
}
50% {
left: 90%;
-webkit-transform: translateY(0vh) scale(0.6);
transform: translateY(0vh) scale(0.6);
}
60% {
left: 110%;
-webkit-transform: translateY(0vh) scale(0.6);
transform: translateY(0vh) scale(0.6);
}
100% {
left: 110%;
opacity: 1;
-webkit-transform: translateY(0vh) scale(0.6);
transform: translateY(0vh) scale(0.6);
}
}
@keyframes fly-right-one {
0% {
left: -10%;
-webkit-transform: scale(0.3);
transform: scale(0.3);
}
10% {
left: 10%;
-webkit-transform: translateY(2vh) scale(0.4);
transform: translateY(2vh) scale(0.4);
}
20% {
left: 30%;
-webkit-transform: translateY(0vh) scale(0.5);
transform: translateY(0vh) scale(0.5);
}
30% {
left: 50%;
-webkit-transform: translateY(4vh) scale(0.6);
transform: translateY(4vh) scale(0.6);
}
40% {
left: 70%;
-webkit-transform: translateY(2vh) scale(0.6);
transform: translateY(2vh) scale(0.6);
}
50% {
left: 90%;
-webkit-transform: translateY(0vh) scale(0.6);
transform: translateY(0vh) scale(0.6);
}
60% {
left: 110%;
-webkit-transform: translateY(0vh) scale(0.6);
transform: translateY(0vh) scale(0.6);
}
100% {
left: 110%;
opacity: 1;
-webkit-transform: translateY(0vh) scale(0.6);
transform: translateY(0vh) scale(0.6);
}
}
@-webkit-keyframes fly-right-two {
0% {
left: -10%;
opacity: 1;
-webkit-transform: translateY(-2vh) scale(0.5);
transform: translateY(-2vh) scale(0.5);
}
10% {
left: 10%;
-webkit-transform: translateY(0vh) scale(0.4);
transform: translateY(0vh) scale(0.4);
}
20% {
left: 30%;
-webkit-transform: translateY(-4vh) scale(0.6);
transform: translateY(-4vh) scale(0.6);
}
30% {
left: 50%;
-webkit-transform: translateY(1vh) scale(0.45);
transform: translateY(1vh) scale(0.45);
}
40% {
left: 70%;
-webkit-transform: translateY(-2.5vh) scale(0.5);
transform: translateY(-2.5vh) scale(0.5);
}
50% {
left: 90%;
-webkit-transform: translateY(0vh) scale(0.45);
transform: translateY(0vh) scale(0.45);
}
51% {
left: 110%;
-webkit-transform: translateY(0vh) scale(0.45);
transform: translateY(0vh) scale(0.45);
}
100% {
left: 110%;
-webkit-transform: translateY(0vh) scale(0.45);
transform: translateY(0vh) scale(0.45);
}
}
@keyframes fly-right-two {
0% {
left: -10%;
opacity: 1;
-webkit-transform: translateY(-2vh) scale(0.5);
transform: translateY(-2vh) scale(0.5);
}
10% {
left: 10%;
-webkit-transform: translateY(0vh) scale(0.4);
transform: translateY(0vh) scale(0.4);
}
20% {
left: 30%;
-webkit-transform: translateY(-4vh) scale(0.6);
transform: translateY(-4vh) scale(0.6);
}
30% {
left: 50%;
-webkit-transform: translateY(1vh) scale(0.45);
transform: translateY(1vh) scale(0.45);
}
40% {
left: 70%;
-webkit-transform: translateY(-2.5vh) scale(0.5);
transform: translateY(-2.5vh) scale(0.5);
}
50% {
left: 90%;
-webkit-transform: translateY(0vh) scale(0.45);
transform: translateY(0vh) scale(0.45);
}
51% {
left: 110%;
-webkit-transform: translateY(0vh) scale(0.45);
transform: translateY(0vh) scale(0.45);
}
100% {
left: 110%;
-webkit-transform: translateY(0vh) scale(0.45);
transform: translateY(0vh) scale(0.45);
}
}
/*.bird-container > .lock{
  border:2px solid ##f4ba00;
width:50px;
height:40px;
}*/
.wrapper-login .pulse {
-webkit-animation: pulse 2s linear infinite;
        animation: pulse 2s linear infinite;
-webkit-transform: scale(1);
        transform: scale(1);
background-image: url('/pulse.png');
position: absolute;
width:688px;
height:677px;
-webkit-animation-delay:2s;
        animation-delay:2s;
opacity:0;
background-repeat:no-repeat;
margin-left: -370px;
z-index: 9
}

@-webkit-keyframes pulse {
0% {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
opacity: 0.7;
}
70% {
 -webkit-transform: scale(1);
 transform: scale(1);
 opacity: 0;
}
100% {
  -webkit-transform: scale(0.95);
 transform: scale(0.95);
 opacity: 0;
}
}
@keyframes pulse {
  0% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  opacity: 0.7;
  }
  70% {
   -webkit-transform: scale(1);
   transform: scale(1);
   opacity: 0;
  }
  100% {
    -webkit-transform: scale(0.95);
   transform: scale(0.95);
   opacity: 0;
  }
  }
.logo {
background-image:url('/logo_transparent.png');
width: 120px;
 height: 73px;
 display: flex;
z-index: 1;
position: absolute;
background-repeat:no-repeat;
 background-size:100%;
}

.wrapper-login .wind-turbine {
position: fixed;
bottom: 0%;
left: 82%;
z-index:9999;
}
@media only screen and (max-width: 1220px) {
.wrapper-login .wind-turbine {
  bottom: -20%;
  left: 85%;
}
}
.wrapper-login .pole {
width: 72px;
height:465px;
margin: 0;
background-image:url('/pole.png');
}

.wrapper-login .rotor {
width: 54px;
height: 54px;
background-image:url('/rotor.png');
border-radius: 50%;
position: absolute;
-webkit-animation-name: rotate;
        animation-name: rotate;
-webkit-animation-duration: 20s;
        animation-duration: 20s;
-webkit-animation-iteration-count: 1000;
        animation-iteration-count: 1000;
-webkit-animation-timing-function: linear;
        animation-timing-function: linear;
margin: 8px;
z-index:9999;
}

@-webkit-keyframes rotate {
from {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}
  to {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
}

@keyframes rotate {
from {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}
  to {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
}

.wrapper-login .blade {
width: 88px;
height: 423px;
background-image:url('/blade1.png');
position: absolute;
top: 26px;
left: 26px;
border-radius: 0 3em 5em 0;
-webkit-transform-origin: 0 0;
        transform-origin: 0 0;
}

.wrapper-login .blade1 {
-webkit-transform: rotate(0deg);
        transform: rotate(0deg);
}

.wrapper-login .blade2 {
-webkit-transform: rotate(120deg);
        transform: rotate(120deg);
}

.wrapper-login .blade3 {
-webkit-transform: rotate(240deg);
        transform: rotate(240deg);
}
body {
  overflow-x: hidden;
}

a, a:hover{
  color:#ffffff;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

#menu-toggle {
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
  color:#ffffff;
  z-index:9999;
}
/*** the below css are added by tom *****/
#navigation-bar .border-bottom-yellow{
  background: #0f1027 url("/header.png") center no-repeat !important;
  background-size: cover;
}
#navigation-bar .navbar-dark .navbar-toggler{
  background-color: #1d1f37;
}
#sidebar-wrapper {background-color:#1d1f37;}
.navbar {background-color:#0f1027; border-bottom:1px solid #33bcb3;}
.navbar .nav-item {background-color:#1d1f37;}
.list-group-item {background-color:#1d1f37; color:#ffffff !important; border-bottom: 1px solid #000000;}
.list-group-item > .btn {color:#ffffff  !important;}
.container-fluid {background-color:#242846;}
.list-group-item:hover, .list-group-item.active, .list-group-item-action:focus, .list-group-item-action:hover{ background-color: #33bcb3 !important;}
.navbar .nav-item.profile, .nav-item.profile-drop-down, .nav-item.profile-drop-down > .btn, .nav-item.profile-drop-down > .btn.focus, .nav-item.profile-drop-down > .btn:focus{ color:#ffffff !important; border:none !important;box-shadow:none;}
.navbar .nav-item.profile{ background-color: transparent !important;}
.nav-item.profile-drop-down{ background-color:#1d1f37 !important; border-radius: 5px;}
.generators .body{margin:0 2.5%;}
.generators .my-title{position: relative;left:2.5%;}
.container-inner-wrapper{ padding:0 2.5%;}
.container-inner-wrapper-header, .farm-header{
  background-color:#3D6188;
  width:auto;
  max-width: 400px;
  height:auto;
  padding:10px;
  color:#ffffff;
}
.container-inner-wrapper table {
  border:1px solid #2A4B6E !important;
}
.container-inner-wrapper .table td, .table th{
  border-color: #2A4B6E !important;
}
.container-inner-wrapper .table th{
  background-color: #2A4B6E !important;
}

.date-picker .btn-link, table .btn-link, .btn-link{
  color:#ffffff !important;
}
.date-picker .react-datetimerange-picker, .date-picker .react-datetimerange-picker__wrapper, .date-picker-opener{
  background-color:  #33bcb3;
  border-color: #33bcb3;
  padding:5px;
}
.date-picker *, .date-picker-opener * {
  color:#000000;
}
.date-picker-opener {
  width:287px;
  overflow: hidden;
  padding:10px;
}
.date-picker-opener .pull-left{
  float:left;
}
.date-picker-opener .pull-right{
  float:right;
  margin-right:5px;
}

.container-fluid.overflow-hidden{ overflow: hidden;}
.more-details, .more-details *, .shutdowns-site-card .card-footer, .all-site-card .card-footer, .detections-site-card .card-footer{
  color:#ffffff;
  background-color: #324B6B;
  border:0px;
}

.shutdowns-site-card .card-footer .text-muted, .all-site-card .card-footer .text-muted, .detections-site-card .card-footer .text-muted{
  color:#fff !important;
}
.more-details a:hover{
  color:#ffffff;
  text-decoration: underline;
}
.generator .card-header, .camera .card-header{
  font-size:2.50rem;
  border-bottom:0px;
  background-color: rgb(0, 0, 0, 0);
  padding-bottom:0%;
}

.card .rgb, .card .thermal, .card .active-round  {
  padding:20px;
  background: url("/circle-blue-top.png") top center no-repeat;
  width:120px;
  height:120px;
  display:table;
}
 .vertical-align{
  display:table-cell;
  vertical-align: middle;
  color:#fff;
}
.vertical-align p{
  margin:0;
  color:#fff;
}
.card .detections, .card .deterrences, .card .shutdowns{
  padding:20px;
  background: url("/circle-blue-bottom.png") top center no-repeat;
  width:120px;
  height:120px;
  display:table;
  margin: auto;
}

.generator.card{
  background: #5890AF url("/generator-inactive.png") bottom 20px center no-repeat;
  background-size: cover;
}
.generator.card:hover{
  background: #6ABBF1 url("/generator-active.png") bottom 20px center no-repeat;
  background-size: cover;
}
.camera.card{
  background: #5890AF url("/camera-inactive.png")  bottom  center no-repeat;
  background-size: cover;
}
.camera.card:hover{
  background: #6ABBF1 url("/camera-active.png") bottom  center no-repeat;
  background-size: cover;
}

.all-site-card .card-body{
  background: #9a9a9a url("/camera-all-generators.png") center no-repeat;
}
.detections-site-card .card-body{
  background: #9a9a9a url("/camera-all-active-generators.png") center no-repeat;
}
.deterrences-site-card .card-body{
  background:#9a9a9a url("/camera-deterrences.png") center no-repeat;
}
.shutdowns-site-card .card-body{
  background:#343a40 url("/camera-all-inactive-generators.png") center no-repeat;
}
h1, h2, h3, h4, h5, h6, .table td, table th, .card-header{
  color:#fff;
}
.card-header p{
  font-size:2rem;
}
.date-picker-opener-with-title {
  display:inline-block;
  margin-left:30px;
  font-size:1rem;
}
label.control-label{
  color: #fff;
}
.camera.card .detections, .camera.card .deterrences, .camera.card .shutdowns{
  padding: 0px;
}
.modalWindow .my-title-centered, .modalWindow .table *{
  color:#000;
}
/*** the above css are added by tom ***/
@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}

@media (min-width:1200px) and (max-width: 1570px){
  .generator.card .detections, .generator.card .deterrences, .generator.card .shutdowns{
      background-size:contain;
      width:90px;
      height:100px;
      font-size: 0.75rem;
      padding:0px;
  }
  .camera.card .detections, .camera.card .deterrences, .camera.card .shutdowns{
    background-size:contain;
    width:80px;
    height:100px;
    font-size: 0.75rem;
    padding:0px;
}
.camera.card .active-round{
  background-size:contain;
  width:100px;
  height:100px;
  padding:0px;
}
.camera.card .active-round.horn{
  margin-left:-17px;
}
.camera.card .active-round.stream{
  margin-right:-17px;
}
.card-header p{
  font-size:1.5rem;
}

}
@media (min-width:1200px) and (max-width: 1400px){
  .camera.card .detections, .camera.card .deterrences, .camera.card .shutdowns{
    background-size:contain;
    width:64px;
    height:65px;
    font-size: 0.55rem;
    padding:0px;
}
}
@media (min-width:1570px) and (max-width: 1920px){
  .camera.card .detections, .camera.card .deterrences, .camera.card .shutdowns{
    background-size:contain;
    width:80px;
    height:100px;
    font-size: 0.75rem;
    padding:0px;
}
.camera.card .active-round{
  background-size:contain;
  width:100px;
  height:100px;
  padding:0px;
}
}

@media (min-width:991px) and (max-width: 1115px){
  .generator.card .detections, .generator.card .deterrences, .generator.card .shutdowns{
      background-size:contain;
      width:90px;
      height:100px;
      font-size: 0.75rem;
      padding:0px;
  }

}
@media (max-width:1600px)
{
  #navigation-bar .border-bottom-yellow{
    background-position:left -10rem bottom !important;
  }
}


@media( max-width: 768px){
  .events-table td{
    display:block;
    width:100% !important;
  }
  .table-separator-sm{
    background-color: #193146;
    height: 5px;
    display:table !important;
  }


}

@media( max-width: 768px){
  .annotationDiv{
    width:300px !important;
  }
  .annotation-ok-btn{
    width:100% !important;
  }
  .generator-stats-table td{
    display:block;
    width:100%;
  }
  .smallDeviceTitle{
    font-weight: bold;
    padding-right: 15px;
  }
  .smallDeviceAdjustment{
    margin-bottom:20px;
  }
}

@media (max-height:570px){
  
  .annotationDiv hr, .annotationDiv .form-group{
    margin-top:0 !important;
    margin-bottom: 0 !important;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
	text-align: center;
}

body {
	overflow-x: auto !important;
}

input:focus::-webkit-input-placeholder {
	color: transparent;
}

input:focus:-ms-input-placeholder {
	color: transparent;
}

input:focus::placeholder {
	color: transparent;
}
/* Overriding Bootstrap Colors */
:root {  
	--main-primary:#b4cee4;
	--main-success:#c5e1a5;
	--main-warning:#ede493;
	--main-danger:#ce8d8d;
}
  
.bg-primary {
	background-color: #b4cee4 !important;
	background-color: var(--main-primary) !important;
}

.border-primary {
	border-color: #b4cee4 !important;
	border-color: var(--main-primary) !important;
}

.dropdown-item:active {
	background-color:#b4cee4 !important;
	background-color:var(--main-primary) !important;
}

.dropdown-menu.show {
    display: block;
    max-height: 300px;
    overflow: auto;
}
.bg-success {
	background-color: #c5e1a5 !important;
	background-color: var(--main-success) !important;
}

.success-text {
	color: #373;
}

.border-success {
	border-color: #c5e1a5 !important;
	border-color: var(--main-success) !important;
}

.bg-warning {
	background-color: #ede493 !important;
	background-color: var(--main-warning) !important;
}

.border-warning {
	border-color: #ede493 !important;
	border-color: var(--main-warning) !important;
}

.warning-text {
	color: #a39566;
}

.bg-danger {
	background-color: #ce8d8d !important;
	background-color: var(--main-danger) !important;
}

.border-danger {
	border-color: #ce8d8d !important;
	border-color: var(--main-danger) !important;
}

.table {
	border-bottom: solid 1px #dee2e6;
}

/* Custom */

.transparent-button {
	
}

.black-link{
	color:white;
}

.black-link:hover{
	color:white;
}

.my-title {
	padding-top: 20px;
    text-align: left;
	font-weight: 300;
	font-size: 4rem;
  color:#fff;
}

.my-title-centered {
	font-weight: 300;
	font-size: 2rem;
}

.my-title2 {
	padding-top: 20px;
    text-align: left;
	font-weight: 300;
	font-size: 1.7rem;
  color:#fff;
}

.my-subtitle {
    /* padding-left: 50px; */
    text-align: center;
	font-weight: 400;
	font-size: 1.8rem;
  color:#fff;
}

.my-inner-subtitle {
    padding-left: 70px;
    text-align: left;
	font-weight: 300;
	font-size: 1.8rem;
  color:#fff;
}

.my-form{
  margin-top:5%;
}

.my-hr{
	margin: 3% 5%;
}

.my-colored-circle {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  /* margin-bottom: -2px; */
  border-radius: 50%;
  border-style: solid;
  border-width: 0.5px;
  border-color: #aaa;
  height: 20px;
  width: 20px;  
}

.no-data{
	margin-top: 5%;
	text-align: center;
    font-weight: 200;
}

.register-button {
	width: 40%;
}

li > .my-navlink:hover {
	background-color: #dae0e5 ;
}

li > .my-navlink {
	width: 100%;
}

h4 {
	font-weight: 400;
}
#copy_button{
	margin: 0 10px;
	cursor: pointer;
}
#copy_button:hover{
	color: #6b6b6b
}

.my-spinner{
	margin-top: 5%;
	margin-bottom: 5px;

	width: 5rem;
	height: 5rem;
}

.my-little-spinner{
  margin-top: 5px;
	margin-bottom: 5px;
	width: 20px;
	height: 20px;
}


.disabled{
	pointer-events: none;
    cursor: default;
    color: rgb(177, 175, 175)
}


/* --------------------- Login Page --------------------- */

/*---------------------------------------------*/
input {
	outline: none;
	border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus, input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
input:focus::-moz-placeholder { color:transparent; }
input:focus:-ms-input-placeholder { color:transparent; }

textarea:focus::-webkit-input-placeholder { color:transparent; }
textarea:focus:-moz-placeholder { color:transparent; }
textarea:focus::-moz-placeholder { color:transparent; }
textarea:focus:-ms-input-placeholder { color:transparent; }

input::-webkit-input-placeholder { color: #fff;}
input:-moz-placeholder { color: #fff;}
input::-moz-placeholder { color: #fff;}
input:-ms-input-placeholder { color: #fff;}

textarea::-webkit-input-placeholder { color: #fff;}
textarea:-moz-placeholder { color: #fff;}
textarea::-moz-placeholder { color: #fff;}
textarea:-ms-input-placeholder { color: #fff;}

label {
  margin: 0;
  display: block;
}

/*---------------------------------------------*/
button {
	outline: none !important;
	border: none;
	background: transparent;
}

button:hover {
	cursor: pointer;
}

iframe {
	border: none !important;
}


/*//////////////////////////////////////////////////////////////////
[ Utility ]*/
.txt1 {
  font-family: 'Poppins', sans-serif;  
  font-size: 13px;
  color: #e5e5e5;
  line-height: 1.5;
}


/*//////////////////////////////////////////////////////////////////
[ login ]*/



.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;  
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;  
}

.container-login100::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #b224ef00;
}

.wrap-login100 {
  width: 400px;
  border-radius: 0px;
  overflow: hidden;
  padding: 55px 55px 37px 55px;

  background: #f8f9fa8a;
  
}


/*------------------------------------------------------------------
[ Form ]*/

.login100-form {
  width: 100%;
}

.login100-form-logo {
  font-size: 60px; 
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 auto;
}

.login100-form-title {
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  color: #152D4F;
  line-height: 1.2;
  text-align: center;
  text-transform: none;

  display: block;
}


/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 2px solid rgba(255,255,255,0.24);
  margin-bottom: 30px;
}

.input100 {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #495057 !important;
  line-height: 1.2;

  display: block;
  width: 100%;
  height: 45px;
  background: transparent;
  padding: 0 5px 0 38px;
}

/*---------------------------------------------*/ 
.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  transition: all 0.4s;

  background: #fff;
}

.focus-input100::after {
  font-family: 'Material-Design-Iconic-Font';
  font-size: 22px;
  color: #fff;

  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 6px;
  left: 0px;
  padding-left: 5px;
  transition: all 0.4s;
}

.input100:focus {
  padding-left: 5px;
}

.input100:focus + .focus-input100::after {
  top: -22px;
  font-size: 18px;
}

.input100:focus + .focus-input100::before {
  width: 100%;
}

.has-val.input100 + .focus-input100::after {
  top: -22px;
  font-size: 18px;
}

.has-val.input100 + .focus-input100::before {
  width: 100%;
}

.has-val.input100 {
  padding-left: 5px;
}


/*==================================================================
[ Restyle Checkbox ]*/

.contact100-form-checkbox {
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 35px;
}

.input-checkbox100 {
  display: none;
}

.label-checkbox100 {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: #fff;
  line-height: 1.2;

  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
}

.label-checkbox100::before {
  content: "\f26b";
  font-family: 'Material-Design-Iconic-Font';
  font-size: 13px;
  color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background: #fff;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.input-checkbox100:checked + .label-checkbox100::before {
  color: #555555;
}

.line {
    border-style:solid;
    border-width:2px;  
    border-top-color:#000000;
}
/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.login100-form-btn {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #08173C;
  line-height: 1.2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 120px;
  height: 50px;
  border-radius: 0px;

  background: #FD8200;
  position: relative;
  z-index: 1;
  transition: all 0.4s;
}

.login100-form-btn::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 0px;
  background-color: #FDB413;
  top: 0;
  left: 0;
  opacity: 1;
  transition: all 0.4s;
}

.login100-form-btn:hover {
  color: #fff;
}

.login100-form-btn:hover:before {
  opacity: 0;
}


/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 576px) {
  .wrap-login100 {
    padding: 55px 15px 37px 15px;
  }
}



/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0px;
  pointer-events: none;

  font-family: 'Poppins', sans-serif;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f12a";
  font-family: FontAwesome;
  font-size: 16px;
  color: #c80000;

  display: block;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 5px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}


/* Utils */
.p-b-34 {padding-bottom: 34px;}
.p-t-27 {padding-top: 27px;}
.p-t-90 {padding-top: 20px;}
.p-t-90b {padding-top: 20px;}

