body {
  overflow-x: hidden;
}

a, a:hover{
  color:#ffffff;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

#menu-toggle {
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
  color:#ffffff;
  z-index:9999;
}
/*** the below css are added by tom *****/
#navigation-bar .border-bottom-yellow{
  background: #0f1027 url("/header.png") center no-repeat !important;
  background-size: cover;
}
#navigation-bar .navbar-dark .navbar-toggler{
  background-color: #1d1f37;
}
#sidebar-wrapper {background-color:#1d1f37;}
.navbar {background-color:#0f1027; border-bottom:1px solid #33bcb3;}
.navbar .nav-item {background-color:#1d1f37;}
.list-group-item {background-color:#1d1f37; color:#ffffff !important; border-bottom: 1px solid #000000;}
.list-group-item > .btn {color:#ffffff  !important;}
.container-fluid {background-color:#242846;}
.list-group-item:hover, .list-group-item.active, .list-group-item-action:focus, .list-group-item-action:hover{ background-color: #33bcb3 !important;}
.navbar .nav-item.profile, .nav-item.profile-drop-down, .nav-item.profile-drop-down > .btn, .nav-item.profile-drop-down > .btn.focus, .nav-item.profile-drop-down > .btn:focus{ color:#ffffff !important; border:none !important;box-shadow:none;}
.navbar .nav-item.profile{ background-color: transparent !important;}
.nav-item.profile-drop-down{ background-color:#1d1f37 !important; border-radius: 5px;}
.generators .body{margin:0 2.5%;}
.generators .my-title{position: relative;left:2.5%;}
.container-inner-wrapper{ padding:0 2.5%;}
.container-inner-wrapper-header, .farm-header{
  background-color:#3D6188;
  width:auto;
  max-width: 400px;
  height:auto;
  padding:10px;
  color:#ffffff;
}
.container-inner-wrapper table {
  border:1px solid #2A4B6E !important;
}
.container-inner-wrapper .table td, .table th{
  border-color: #2A4B6E !important;
}
.container-inner-wrapper .table th{
  background-color: #2A4B6E !important;
}

.date-picker .btn-link, table .btn-link, .btn-link{
  color:#ffffff !important;
}
.date-picker .react-datetimerange-picker, .date-picker .react-datetimerange-picker__wrapper, .date-picker-opener{
  background-color:  #33bcb3;
  border-color: #33bcb3;
  padding:5px;
}
.date-picker *, .date-picker-opener * {
  color:#000000;
}
.date-picker-opener {
  width:287px;
  overflow: hidden;
  padding:10px;
}
.date-picker-opener .pull-left{
  float:left;
}
.date-picker-opener .pull-right{
  float:right;
  margin-right:5px;
}

.container-fluid.overflow-hidden{ overflow: hidden;}
.more-details, .more-details *, .shutdowns-site-card .card-footer, .all-site-card .card-footer, .detections-site-card .card-footer{
  color:#ffffff;
  background-color: #324B6B;
  border:0px;
}

.shutdowns-site-card .card-footer .text-muted, .all-site-card .card-footer .text-muted, .detections-site-card .card-footer .text-muted{
  color:#fff !important;
}
.more-details a:hover{
  color:#ffffff;
  text-decoration: underline;
}
.generator .card-header, .camera .card-header{
  font-size:2.50rem;
  border-bottom:0px;
  background-color: rgb(0, 0, 0, 0);
  padding-bottom:0%;
}

.card .rgb, .card .thermal, .card .active-round  {
  padding:20px;
  background: url("/circle-blue-top.png") top center no-repeat;
  width:120px;
  height:120px;
  display:table;
}
 .vertical-align{
  display:table-cell;
  vertical-align: middle;
  color:#fff;
}
.vertical-align p{
  margin:0;
  color:#fff;
}
.card .detections, .card .deterrences, .card .shutdowns{
  padding:20px;
  background: url("/circle-blue-bottom.png") top center no-repeat;
  width:120px;
  height:120px;
  display:table;
  margin: auto;
}

.generator.card{
  background: #5890AF url("/generator-inactive.png") bottom 20px center no-repeat;
  background-size: cover;
}
.generator.card:hover{
  background: #6ABBF1 url("/generator-active.png") bottom 20px center no-repeat;
  background-size: cover;
}
.camera.card{
  background: #5890AF url("/camera-inactive.png")  bottom  center no-repeat;
  background-size: cover;
}
.camera.card:hover{
  background: #6ABBF1 url("/camera-active.png") bottom  center no-repeat;
  background-size: cover;
}

.all-site-card .card-body{
  background: #9a9a9a url("/camera-all-generators.png") center no-repeat;
}
.detections-site-card .card-body{
  background: #9a9a9a url("/camera-all-active-generators.png") center no-repeat;
}
.deterrences-site-card .card-body{
  background:#9a9a9a url("/camera-deterrences.png") center no-repeat;
}
.shutdowns-site-card .card-body{
  background:#343a40 url("/camera-all-inactive-generators.png") center no-repeat;
}
h1, h2, h3, h4, h5, h6, .table td, table th, .card-header{
  color:#fff;
}
.card-header p{
  font-size:2rem;
}
.date-picker-opener-with-title {
  display:inline-block;
  margin-left:30px;
  font-size:1rem;
}
label.control-label{
  color: #fff;
}
.camera.card .detections, .camera.card .deterrences, .camera.card .shutdowns{
  padding: 0px;
}
.modalWindow .my-title-centered, .modalWindow .table *{
  color:#000;
}
/*** the above css are added by tom ***/
@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}

@media (min-width:1200px) and (max-width: 1570px){
  .generator.card .detections, .generator.card .deterrences, .generator.card .shutdowns{
      background-size:contain;
      width:90px;
      height:100px;
      font-size: 0.75rem;
      padding:0px;
  }
  .camera.card .detections, .camera.card .deterrences, .camera.card .shutdowns{
    background-size:contain;
    width:80px;
    height:100px;
    font-size: 0.75rem;
    padding:0px;
}
.camera.card .active-round{
  background-size:contain;
  width:100px;
  height:100px;
  padding:0px;
}
.camera.card .active-round.horn{
  margin-left:-17px;
}
.camera.card .active-round.stream{
  margin-right:-17px;
}
.card-header p{
  font-size:1.5rem;
}

}
@media (min-width:1200px) and (max-width: 1400px){
  .camera.card .detections, .camera.card .deterrences, .camera.card .shutdowns{
    background-size:contain;
    width:64px;
    height:65px;
    font-size: 0.55rem;
    padding:0px;
}
}
@media (min-width:1570px) and (max-width: 1920px){
  .camera.card .detections, .camera.card .deterrences, .camera.card .shutdowns{
    background-size:contain;
    width:80px;
    height:100px;
    font-size: 0.75rem;
    padding:0px;
}
.camera.card .active-round{
  background-size:contain;
  width:100px;
  height:100px;
  padding:0px;
}
}

@media (min-width:991px) and (max-width: 1115px){
  .generator.card .detections, .generator.card .deterrences, .generator.card .shutdowns{
      background-size:contain;
      width:90px;
      height:100px;
      font-size: 0.75rem;
      padding:0px;
  }

}
@media (max-width:1600px)
{
  #navigation-bar .border-bottom-yellow{
    background-position:left -10rem bottom !important;
  }
}


@media( max-width: 768px){
  .events-table td{
    display:block;
    width:100% !important;
  }
  .table-separator-sm{
    background-color: #193146;
    height: 5px;
    display:table !important;
  }


}

@media( max-width: 768px){
  .annotationDiv{
    width:300px !important;
  }
  .annotation-ok-btn{
    width:100% !important;
  }
  .generator-stats-table td{
    display:block;
    width:100%;
  }
  .smallDeviceTitle{
    font-weight: bold;
    padding-right: 15px;
  }
  .smallDeviceAdjustment{
    margin-bottom:20px;
  }
}

@media (max-height:570px){
  
  .annotationDiv hr, .annotationDiv .form-group{
    margin-top:0 !important;
    margin-bottom: 0 !important;
  }
}